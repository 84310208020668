import axiosApiInstance from "../app/axios";
import { API_BASE_URL, PARKIT_API_BASE_URL } from "../app/utils";
import { getJwtToken } from "../app/common";

export const getParkitSubscriptions = async (page, size, sort, sortBy, chargeStatuses) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/subscriptions?page=${page}&size=${size}&sort=${sort}&sortBy=${sortBy}&chargeStatuses=${chargeStatuses}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const manualChargeParkitSubscription = async (subscription_id) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/wallet/v1/wallets/*/subscriptions/${subscription_id}/manual-charge`,
    {},
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const getParkitSubscriptionChargeHistories = async (wallet_id, subscription_id) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/wallet/v1/wallets/${wallet_id}/charges?subscriptionId=${subscription_id}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const pauseChargeSubscription = async (subscription_id, wallet_id) =>
  axiosApiInstance.post(
    `${PARKIT_API_BASE_URL}/parkit/v2/subscriptions/pause-charge`,
    {
      walletId: wallet_id,
      subscriptionId: subscription_id
    },
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

  export const recreateSubscription = async (subscription) =>
    axiosApiInstance.post(
      `${PARKIT_API_BASE_URL}/parkit/v2/subscriptions/recreate`,
      subscription,
      { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

  export const updateSubscription = async (id, subscription) =>
    axiosApiInstance.post(
      `${PARKIT_API_BASE_URL}/parkit/v2/subscriptions/${id}/patch`,
      subscription,
      { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );