import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { SubscriptionChargeStatus } from '../../../app/constants';
import { updateSubscription, getParkitSubscriptions, manualChargeParkitSubscription, pauseChargeSubscription } from '../../../api/parkitSubscriptionAPI';

export const subscriptionSlice = createSlice({
  name: 'parkitFailedSubscription',
  initialState: {
    failedSubscriptions: [],
    failedSubscriptionPagination: {},
    failedSubscriptionPage: 0,
    failedSubscriptionRowsPerPage: 10,
    sort: 'desc',
    sortBy: 'nextBillingDate',
    selectedFailedSubscriptionId: null,
    selectedWalletId: null,
    selectedFilter: {
      chargeStatus: [SubscriptionChargeStatus.FAILED, SubscriptionChargeStatus.RETRYING],
    },
    confirmRetryPaymentModalOpen: false,
    confirmPauseChargeModalOpen: false,
    filterModalOpen: false,
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    openConfirmRetryPaymentModal: (state) => {
      state.confirmRetryPaymentModalOpen = true;
    },
    closeConfirmRetryPaymentModal: (state) => {
      state.confirmRetryPaymentModalOpen = false;
    },
    setSelectedFailedSubscriptionId: (state, action) => {
      state.selectedFailedSubscriptionId = action.payload;
    },
    setFailedSubscriptionPage: (state, action) => {
      state.failedSubscriptionPage = action.payload;
    },
    setFailedSubscriptionRowsPerPage: (state, action) => {
      state.failedSubscriptionRowsPerPage = action.payload;
    },
    setFailedSubscriptionPagination: (state, action) => {
      state.failedSubscriptionPagination = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setFailedSubscriptions: (state, action) => {
      state.failedSubscriptions = action.payload;
    },
    setSelectedWalletId: (state, action) => {
      state.selectedWalletId = action.payload;
    },
    setSelectedFilter: (state, action) => {
      state.selectedFilter = action.payload;
    },
    openConfirmPauseChargeModal: (state) => {
      state.confirmPauseChargeModalOpen = true;
    },
    closeConfirmPauseChargeModal: (state) => {
      state.confirmPauseChargeModalOpen = false;
    },
    showFilterModal: (state, action) => {
      state.filterModalOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  }
});

export const { setSort, setSortBy, setSelectedFilter, showFilterModal, openConfirmRetryPaymentModal, closeConfirmRetryPaymentModal, setSelectedFailedSubscriptionId, setFailedSubscriptionPage, setFailedSubscriptionRowsPerPage, setFailedSubscriptionPagination, setFailedSubscriptions, setSelectedWalletId, openConfirmPauseChargeModal, closeConfirmPauseChargeModal, setSuccessMessage, setErrorMessage } = subscriptionSlice.actions;

export const selectState = state => state.parkitFailedSubscription;

export const handleGetFailedSubscriptions = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const { selectedFilter } = state.parkitFailedSubscription;
    const chargeStatues = selectedFilter.chargeStatus.join(",");
    const response = await getParkitSubscriptions(state.parkitFailedSubscription.failedSubscriptionPage, state.parkitFailedSubscription.failedSubscriptionRowsPerPage, state.parkitFailedSubscription.sort, state.parkitFailedSubscription.sortBy, chargeStatues);
    const { content, ...pagination } = response.data;

    dispatch(setFailedSubscriptionPagination(pagination));
    dispatch(setFailedSubscriptions(content));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
    dispatch(setFailedSubscriptions([]));
  } finally {
    dispatch(hideLoading());
  }
}

export const handleRetryPayment = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    await manualChargeParkitSubscription(state.parkitFailedSubscription.selectedFailedSubscriptionId);
    dispatch(handleGetFailedSubscriptions());
    dispatch(closeConfirmRetryPaymentModal());
    dispatch(setSuccessMessage('Subscription payment retry requested successfully'));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export const handlePauseCharge = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    await pauseChargeSubscription(state.parkitFailedSubscription.selectedFailedSubscriptionId, state.parkitFailedSubscription.selectedWalletId);
    dispatch(handleGetFailedSubscriptions());
    dispatch(closeConfirmPauseChargeModal());
    dispatch(setSuccessMessage('Subscription pause charge requested successfully'));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export const handleEditSubscription = (payload) => async (dispatch) => {
  dispatch(showLoading());
  try {
    await updateSubscription(payload.id, payload);
    dispatch(handleGetFailedSubscriptions());
    dispatch(setSuccessMessage('Successfully update subscription'));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export default subscriptionSlice.reducer;