import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Paper, Grid, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import useDisableWhileRequesting from "../../../hooks/useDisableWhileRequesting";

import {
  handleUpdateUser,
  handleUpdateCustomer,
  handleUpdateWallet,
  handleUpdateVehicle,
  handleGetCustomers,
  selectState,
  setSuccessMessage,
  setSelectedId,
  showEditCustomerModal
} from "./customerSlice";
import { hasVehicleChanged } from '../../../app/utils';

const EditCustomerModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const { isDisabled, withDisable } = useDisableWhileRequesting();

  const customer = state.selectedCustomer;
  // User
  const [firstName, setFirstName] = useState(customer.user?.firstName);
  const [lastName, setLastName] = useState(customer.user?.lastName);
  const [contactNumber, setContactNumber] = useState(customer.user?.contactNumber);
  const [company, setCompany] = useState(customer.user?.company);

  // Vehicle
  const originalVehicles = useRef(customer.vehicles || []);
  const [vehicles, setVehicles] = useState(customer.vehicles);

  // Customer
  const [identificationCard, setIdentificationCard] = useState(customer.identificationCard);
  const [passportNumber, setPassportNumber] = useState(customer.passportNumber);
  
  // Wallet
  const [bankName, setBankName] = useState(customer.user?.wallet?.bank?.name);
  const [accountNumber, setAccountNumber] = useState(customer.user?.wallet?.bank?.accountNumber);
  const [accountName, setAccountName] = useState(customer.user?.wallet?.bank?.accountName);

  const submit = async () => {
    try {
      const walletPayload = {
        bank: {
          name: bankName,
          accountNumber,
          accountName
        }
      };
      await withDisable(() => dispatch(handleUpdateWallet(customer.user.wallet.id, walletPayload)));

      const customerPayload = {
        customerId: customer.id,
        identificationCard,
        passportNumber,
      };
      await withDisable(() => dispatch(handleUpdateCustomer(customerPayload)));

      for (let i = 0; i < vehicles.length; i++) {
        const originalVehicle = originalVehicles.current[i];
        const updatedVehicle = vehicles[i];
    
        if (hasVehicleChanged(originalVehicle, updatedVehicle)) {
          const vehiclePayload = {
            registrationNumber: updatedVehicle.registrationNumber,
            model: updatedVehicle.model,
            color: updatedVehicle.color,
          };
          await withDisable(() => dispatch(handleUpdateVehicle(updatedVehicle.id, vehiclePayload)));
        }
      }

      const userPayload = {
        firstName,
        lastName,
        contactNumber,
        company
      };
      await withDisable(() => dispatch(handleUpdateUser(customer.user.id, userPayload)));

      dispatch(showEditCustomerModal(false));
      dispatch(setSuccessMessage("Successfully updated user!"));
      dispatch(handleGetCustomers());
      dispatch(setSelectedId(null));
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleVehicleInputChange = (index, field, value) => {
    const updatedVehicles = vehicles.map((vehicle, i) =>
      i === index ? { ...vehicle, [field]: value } : vehicle
    );
    setVehicles(updatedVehicles);
  };

  return (
    <Modal
      open={true}
      aria-labelledby="add-renting-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showEditCustomerModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Edit Customer</p>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Identity Card Number"
                variant="outlined"
                value={identificationCard}
                onChange={e => setIdentificationCard(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Passport Number"
                variant="outlined"
                value={passportNumber}
                onChange={e => setPassportNumber(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Company"
                variant="outlined"
                value={company}
                onChange={e => setCompany(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Contact Number"
                variant="outlined"
                value={contactNumber}
                onChange={e => setContactNumber(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="outlined-basic"
                label="Bank Name"
                variant="outlined"
                value={bankName}
                onChange={e => setBankName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="outlined-basic"
                label="Account Number"
                variant="outlined"
                value={accountNumber}
                onChange={e => setAccountNumber(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id="outlined-basic"
                label="Account Name"
                variant="outlined"
                value={accountName}
                onChange={e => setAccountName(e.target.value)}
                fullWidth
              />
            </Grid>
            {vehicles.map((vehicle, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id={`vehicleRegistrationNumber-${index}`}
                    label="Vehicle Registration Number"
                    onChange={(e) => handleVehicleInputChange(index, 'registrationNumber', e.target.value)}
                    value={vehicle.registrationNumber}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id={`vehicleModel-${index}`}
                    label="Vehicle Model"
                    onChange={(e) => handleVehicleInputChange(index, 'model', e.target.value)}
                    value={vehicle.model}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id={`vehicleColor-${index}`}
                    label="Vehicle Color"
                    onChange={(e) => handleVehicleInputChange(index, 'color', e.target.value)}
                    value={vehicle.color}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                disabled={isDisabled}
                className={`bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md ${
                  isDisabled ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-[#DE4D65] text-white cursor-pointer'
                }`}
              >
                Update
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default EditCustomerModal;
